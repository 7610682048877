.Upload-box {
  border: 2px dashed lightgray;
  border-radius: 4px;
  padding-top: 48px;
  padding-bottom: 48px;
}

.Upload-icon {
  font-size: 80px !important;
  color: lightgray;
}

.Upload-dragging {
  &.Upload-box {
    border-color: #3f51b4;
  }
  .Upload-icon {
    color: #3f51b4;
  }
}
